<script setup lang="ts">
import {
  LogoHorizontalIcon,
  BurgerMenuIcon,
  CloseIcon,
} from '@glow/shared/components/icons';
import type { LinkWithTitle } from '@glow/shared/utils/types';
import { useBodyScrolling } from '@glow/shared/store/bodyScrolling';
import { useRoutes } from '@glow/shared/lib/routes';
import { socialLinks } from '@glow/shared/lib/constants';
import { useAuthModalStore } from '@glow/shared/features/auth';

const props = withDefaults(
  defineProps<{
    navigationLinks: LinkWithTitle[];
    additionalLinks: LinkWithTitle[];
    authButtonTitle?: string;
    mainLink: LinkWithTitle;
    mainLinkAuthenticatedOnly?: boolean;
  }>(),
  {
    authButtonTitle: 'Войти',
    mainLinkAuthenticatedOnly: true,
  }
);

const { ensureLoggedIn } = useAuthModalStore();
const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;
const isLoggedIn = useIsLoggedIn();
const isBurgerMenuExpanded = ref(false);
const { disableBodyScrolling, enableBodyScrolling } = useBodyScrolling();

watch(isBurgerMenuExpanded, (value) => {
  value ? disableBodyScrolling() : enableBodyScrolling();
});

function onAuthModalShow() {
  isBurgerMenuExpanded.value = false;
  ensureLoggedIn(() => {
    navigateTo(props.mainLink.link, { external: true });
  });
}
</script>

<template>
  <header class="header">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="logo">
          <NuxtLink :to="getUrl('clientHome')">
            <LogoHorizontalIcon />
          </NuxtLink>
        </div>

        <div
          v-if="!isBurgerMenuExpanded"
          class="menu-button"
          @click="isBurgerMenuExpanded = true"
        >
          <BurgerMenuIcon class="burger-icon" />
          <span>Меню</span>
        </div>
        <div v-else class="menu-button" @click="isBurgerMenuExpanded = false">
          <CloseIcon class="close-icon" />
          <span>Закрыть</span>
        </div>

        <div class="d-none d-md-flex gap-3">
          <AppButton
            v-if="!isLoggedIn"
            variant="outlined"
            @click="onAuthModalShow"
          >
            {{ props.authButtonTitle }}
          </AppButton>
          <AppButton
            v-if="!props.mainLinkAuthenticatedOnly || isLoggedIn"
            :to="props.mainLink.link"
          >
            {{ props.mainLink.title }}
          </AppButton>
        </div>
      </div>
    </div>

    <Transition name="menu">
      <nav v-if="isBurgerMenuExpanded" class="menu container">
        <div class="d-none d-lg-flex">
          <div class="image">
            <NuxtImg
              format="webp"
              :src="`${staticUrl}/landing/menu-image.png`"
            />
          </div>
        </div>
        <div>
          <div class="title">Навигация</div>
          <div class="column">
            <NuxtLink
              v-for="(link, index) in props.navigationLinks"
              :key="index"
              :to="link.link"
              @click="isBurgerMenuExpanded = false"
            >
              <span class="item">{{ link.title }}</span>
            </NuxtLink>
          </div>
        </div>
        <div>
          <div class="title">Соц. сети</div>
          <div class="column">
            <NuxtLink :to="socialLinks.vk" target="_blank">
              <span class="item">VK</span>
            </NuxtLink>
            <NuxtLink :to="socialLinks.telegramClientChat" target="_blank">
              <span class="item">Telegram</span>
            </NuxtLink>
            <NuxtLink :to="socialLinks.instagram" target="_blank">
              <span class="item">Instagram</span>
            </NuxtLink>
            <NuxtLink :to="socialLinks.youtube" target="_blank">
              <span class="item">YouTube</span>
            </NuxtLink>
            <NuxtLink :to="socialLinks.tiktok" target="_blank">
              <span class="item">TikTok</span>
            </NuxtLink>
          </div>
        </div>
        <div>
          <div class="title">Дополнительно</div>
          <div class="column">
            <NuxtLink
              v-for="(link, index) in props.additionalLinks"
              :key="index"
              :to="link.link"
            >
              <span class="item">{{ link.title }}</span>
            </NuxtLink>
          </div>
          <div class="d-md-none" style="height: 250px"></div>
        </div>
      </nav>
    </Transition>

    <div
      v-if="isBurgerMenuExpanded"
      class="login d-md-none d-flex flex-column flex-sm-row gap-3"
    >
      <AppButton
        v-if="!isLoggedIn"
        variant="outlined"
        fluid
        @click="onAuthModalShow"
      >
        {{ props.authButtonTitle }}
      </AppButton>
      <AppButton
        v-if="!props.mainLinkAuthenticatedOnly || isLoggedIn"
        fluid
        :to="props.mainLink.link"
      >
        {{ props.mainLink.title }}
      </AppButton>
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  padding: 23px 0;
  background: #fff;

  .logo img {
    width: 122px;
    height: 46px;
  }

  .menu-button {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 17px;
    line-height: 22px;
    cursor: pointer;

    .burger-icon {
      width: 32px;
      height: 22px;
    }

    .close-icon {
      width: 40px;
      height: 40px;
    }

    span {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &:hover {
      color: var(--app-color-primary);
    }
  }
}

.menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 50px;
  max-height: 490px;
  gap: 1rem;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 0 16px;
  }

  .image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .item {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: var(--app-color-black);
    cursor: pointer;

    &:hover {
      color: var(--app-color-primary);
    }

    @include media-breakpoint-only(lg) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }
}

.login {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 16px;
  background: #fff;
}
</style>
